<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Testimonio - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                 <b-col md="12">
                  <b-form-group label="Nombres:">
                    <b-form-input disabled type="text" v-model="testimonial.name"></b-form-input>
                    <small v-if="errors.name"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Email:">
                    <b-form-input disabled type="email" v-model="testimonial.email"></b-form-input>
                    <small v-if="errors.email"  class="form-text text-danger" >Ingrese un correo</small>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Pais:">
                    <b-form-select disabled v-model="testimonial.country" :options="countries"></b-form-select>
                    <small v-if="errors.country" class="form-text text-danger" >Seleccione un pais</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Testimonio:">
                    <b-form-textarea disabled type="text" rows="5" v-model="testimonial.testimony"></b-form-textarea>
                    <small v-if="errors.testimony" class="form-text text-danger" >Ingrese su testimonio</small>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/testimonio/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
// components
import LoadingComponent from './../pages/Loading'

export default {
  name: "IncomeEdit",
  props: ["id_testimonial"],
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Testimonial',
      role: 3,
      testimonial: {
          id_testimonial:'',
          name:'',
          email:'',
          country:'PE',
          testimony:'',
          state:1,
      },
      countries:[],

      errors: {
        name: false,
        email: false,
        country: false,
        testimony: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListCountries();
    this.ViewTestimonial();
  },
  methods: {

    ViewTestimonial,
    EditTestimonial,
    Validate,
    ListCountries,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListCountries() {
  let me = this;
  let url = me.url_base + "list-countries";
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    me.countries = [{value:'',text:'-- Seleccione un pais --'}];
    for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.countries.push({value:element.code, text: element.name });
    }
   
    
  })
  .catch((error) => {
  });
}

//ver usuario
function ViewTestimonial() {
  let id_testimonial = je.decrypt(this.id_testimonial);
  let me = this;
  let url = this.url_base + "testimonial/view/" + id_testimonial;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.testimonial.id_testimonial = response.data.result.id_testimonial;
        me.testimonial.name = response.data.result.name;
        me.testimonial.email = response.data.result.email;
        me.testimonial.country = response.data.result.country;
        me.testimonial.testimony = response.data.result.testimony;
        me.testimonial.state = response.data.result.state;
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text:"A ocurrido un error", timer: 3000,})
      me.isLoading = false;
    });
}

//editar usuario
function EditTestimonial() {

  let me  = this;
  let url = me.url_base + "testimonial/edit";
  let data = this.testimonial;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: this.token, module: this.module, role: this.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text:response.data.message, timer: 3000,})
      }else {
        Swal.fire({ icon: 'error', text:response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {

  this.errors.name = this.testimonial.name.length == 0 ? true : false;
  this.errors.email = this.testimonial.email.length == 0 ? true : false;
  this.errors.country = this.testimonial.country.length == 0 ? true : false;
  this.errors.testimony = this.testimonial.testimony.length == 0 ? true : false;
  
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.email) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.country) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.testimony) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el testimonio ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditTestimonial();
    }
  });
}
</script>
